@font-face {
    font-family: 'SFRoundedFallback';
    src: url('./fonts/SFRounded-Black.woff2') format('woff2'),
        url('./fonts/SFRounded-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SFRoundedFallback';
    src: url('./fonts/SFRounded-Bold.woff2') format('woff2'),
        url('./fonts/SFRounded-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SFRoundedFallback';
    src: url('./fonts/SFRounded-Heavy.woff2') format('woff2'),
        url('./fonts/SFRounded-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

